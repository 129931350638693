import React, { Component } from 'react';
import * as Constants from '../constants';
import * as Utils from '../utils';
import ProductPricing from '../components/ProductPricing'

// import { Form } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
import Stepper from 'react-stepper-horizontal';
import { ValidationForm, TextInput, SelectGroup, Radio } from "react-bootstrap4-form-validation";
import { LinkContainer } from 'react-router-bootstrap';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import iconEngage from '../img/icon-engage.png';
// import iconInteract from '../img/icon-interact.png';
// import iconInform from '../img/icon-inform.png';
// import iconInspire from '../img/icon-inspire.png';
// import iconConnect from '../img/icon-connect.png';
// import iconEnhance from '../img/icon-enhance.png';
// import FloatingLabel from "react-bootstrap-floating-label";

export default class Home extends Component {
  constructor(props, context) {
    super(props, context)

    Utils.disableNumberScroll();

    this.localCache = Utils.getLocalCache();

    this.digitalSignageForm = React.createRef();
    this.onHoldMarketingForm = React.createRef();
    this.onPremiseMessagingForm = React.createRef();
    this.wifiMarketingForm = React.createRef();
    this.scentMarketingForm = React.createRef();

    this.validatingIndex = 0;
    this.forms = [
      {form: this.digitalSignageForm, productType : Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM},
      {form: this.onHoldMarketingForm, productType : Constants.ON_HOLD_MARKETING_PRODUCT_ENUM},
      {form: this.onPremiseMessagingForm, productType : Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM},
      {form: this.wifiMarketingForm, productType : Constants.WIFI_MARKETING_PRODUCT_ENUM},
    ]

    if(this.localCache.selectedProducts == null || this.localCache.selectedProducts.length === 0) {
      window.location.assign('/');

      this.state = {
        redirecting: true,
      }

      return;
    }

    if(!this.localCache.productDetails){
      this.localCache.productDetails = {};
    }


    if(!this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM])
      this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM]= {
        manuallySetNumDisplays: 1,
        numDisplays: 1,
        numLocations: 1,
        contentLibrary: '',
        scheduleDemo: false,
        installationType: 'No',
        itStaffAvailable: '',
      };

    if(!this.localCache.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM])
      this.localCache.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM] = {
        numLocations: 1,
        messageProductFrequency: '',
        phoneProvider: '',
        phoneMake: '',
        phoneModel: '',
        installationType: 'No',
        itStaffAvailable: '',
      };

    if(!this.localCache.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM])
      this.localCache.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM] = {
        numLocations: 1,
        messagingType: '',
        installationType: 'No',
        itStaffAvailable: '',
        amplifierInfo: '',
        connectionType: '',
        phoneProvider: '',
        phoneMake: '',
        phoneModel: '',
        networkSwitchMake: '',
        networkSwitchModel: '',
      };

    if(!this.localCache.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM])
      this.localCache.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM] = {
        numLocations: 1,
        updateFrequency: '',
        internetProvider: '',
        routerMake: '',
        installationType: 'No',
        itStaffAvailable: '',
      }

    // if(!this.localCache.productDetails[Constants.SCENT_MARKETING_PRODUCT_ENUM])
    //   this.localCache.productDetails[Constants.SCENT_MARKETING_PRODUCT_ENUM] =  {
    //     scentType: '',
    //     airHandlerLocation: '',
    //     numberUnits: '',
    //     squareFootage: '',
    //     ceilingHeight: '',
    //   }

    this.state = {
      selectedProducts: this.localCache.selectedProducts,
      productDetails: this.localCache.productDetails,
    }

  }

  itemPricingUpdated = (itemPricing) => {
    this.localCache.itemPricing = itemPricing;
    Utils.storeLocalCache(this.localCache);
  };


  updateValue = (productType, field, value) => {
    let productDetails = this.state.productDetails;
    productDetails[productType][field] = value;

    this.setState({[productDetails]: productDetails});

    this.localCache.productDetails[productType][field] = value;
    //for now force them to click next in step two after changing a value to force validation
    //until I can think of a better way
    this.localCache.stepTwoCompleted = false;
    Utils.storeLocalCache(this.localCache);
  };

  handleSliderChange = (productType, field, value) => {
    this.updateValue(productType, field, value);

    //store the last time that the number of displays was set so that we can
    //default back to it if the number of locations changes
    if(productType == Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM){
      if(field == 'numDisplays') {
        this.updateValue(productType, 'manuallySetNumDisplays', value);
      }else if(field == 'numLocations'){
        setTimeout(this.validateDigitalSignageNumDisplays, 0);
      }

      const signageDetails = this.localCache.productDetails[productType];

      this.updateValue(productType, 'scheduleDemo', signageDetails.numLocations > Constants.MAX_LOCATIONS || signageDetails.numDisplays > Constants.MAX_DISPLAYS);
    }

  };

  validateDigitalSignageNumDisplays = () => {
    //cannot have less digital signage displays than locations
    let digitalSignageDetails = this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM];

    let targetNumDisplays = digitalSignageDetails.manuallySetNumDisplays || 1;
    const numLocations = digitalSignageDetails.numLocations;
    const numDisplays = targetNumDisplays < numLocations ? numLocations : targetNumDisplays;

    if(numDisplays != digitalSignageDetails.numDisplays){
      this.updateValue(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM, 'numDisplays', numDisplays);
    }
  }


  handleInputChange = (e) => {
    const split = e.target.name.split('|');

    this.updateValue(split[0], split[1], e.target.value);
  };

  completeStepTwo = (e) => {
    e.preventDefault();

    //ensure all of the forms pass validation
    this.validatingIndex = -1;
    this.validateNextForm(e);
  }

  validateNextForm = (e) => {
    this.validatingIndex++;

    if(this.validatingIndex >= this.forms.length){

      this.goToNextStep();
      return;
    }

    const form = this.forms[this.validatingIndex];
    if(this.state.selectedProducts.includes(form.productType)){
      form.form.current.resetValidationState();
      form.form.current.handleSubmit(e);
    }else{
      this.validateNextForm(e);
    }
  }

  shouldGoToStepThree = () => {

    for(let x = 0; x<this.localCache.selectedProducts.length; x++){
      const productType = this.localCache.selectedProducts[x];

      if(productType === Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM && this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].scheduleDemo)
        continue;

      if(Constants.PRODUCT_INFO_MAP[productType].canPurchase)
        return true;
    }
    return false;
  }

  goToNextStep = () =>{
    this.localCache.stepTwoCompleted = true;
    Utils.storeLocalCache(this.localCache);
    const location = this.shouldGoToStepThree() ? '/step-3' : '/thank-you';

    setTimeout(function(){
      window.location = location
    }, 0);
  }

  handleFormError = (e, formData, errorInputs) => {
    //hacky way to reset the for, is-valid state that seemed to get messed up by calling
    //handleSubmit directly
    const inputs = this.forms[this.validatingIndex].form.current.inputs;
    for( const key in inputs) {
      inputs[key].clearError();
      inputs[key].checkError();
    }
  }

  handleFormSubmit = (e, formData, inputs) => {

    this.forms[this.validatingIndex].form.current.resetValidationState();

    this.validateNextForm(e);
  }

  getNumDigitalSignageDisplay(value){
    return value > Constants.MAX_DISPLAYS ? Constants.MAX_DISPLAYS + '+' : value;
  }

  getDigitalSignageLocationsDisplay(value){
    return value > Constants.MAX_LOCATIONS ? Constants.MAX_LOCATIONS + '+' : value;
  }

  render () {
    if(this.state.redirecting)
      return (<div></div>);

      return (
        <div className="registration-inner-content">
          <div className="bs-form">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="stepper">
                    <Stepper
                      steps={ [
                        {title: 'Customer Info', href: "/"},
                        {title: 'Plan Details'},
                        {title: 'Payment Info', href:"/step-3"}
                      ] }
                      activeStep={ this.localCache.stepTwoCompleted ? 2 : 1}
                      completeColor="#44218d"
                      activeColor="#44218d"
                      defaultBarColor="#ddd"
                      completeBarColor="#44218d"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-8">

                  {this.state.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM) &&

                  <ValidationForm
                    onSubmit={this.handleFormSubmit}
                    onErrorSubmit={this.handleFormError}
                    ref={this.digitalSignageForm}
                    style={{marginBottom: "0"}}>
                    <h3 className="mt-4 mb-4">Digital Signage Details:</h3>

                    <div className="card card--no-hover variable-top-margin">
                      <div className="mid">
                        <h4 className="mt-2">Number of Displays: &nbsp;&nbsp;<span
                          className="purple">{this.getNumDigitalSignageDisplay(this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numDisplays)}</span></h4>
                        <p>Use the slider below to select the number of displays you want to use. You'll need one software
                          license per display.</p>
                        <div className='slider'>
                          <Slider
                            name={'numDisplays'}
                            min={this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numLocations}
                            max={Constants.MAX_DISPLAYS + 1}
                            value={this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numDisplays}
                            onChange={(value) => this.handleSliderChange(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM, 'numDisplays', value)}
                            format={this.getNumDigitalSignageDisplay}
                          />
                        </div>

                        <hr/>

                        <h4 className="mt-2">Number of Locations: &nbsp;&nbsp;<span
                          className="purple">{this.getDigitalSignageLocationsDisplay(this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numLocations)}</span></h4>
                        <p>Use the slider below to select the number of locations for your business.</p>
                        <div className='slider'>
                          <Slider
                            min={1}
                            max={Constants.MAX_LOCATIONS + 1}
                            value={this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numLocations}
                            onChange={(value) => this.handleSliderChange(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM, 'numLocations', value)}
                            format={this.getDigitalSignageLocationsDisplay}
                          />
                        </div>

                        {this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].scheduleDemo &&
                        <div className="max-signage-message">
                          <img src={iconEngage} alt="Spectrio Engage Logo"/>
                          <b>Note:</b> In order to sign up for 50+ screens or 10+ locations, you'll need to schedule a
                          consultation
                          after registering.
                        </div>
                        }
                        <hr/>
                        <h4>Installation Services Required?</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Will you need Spectrio to provide installation services?</p>
                        <div className="form-group keep-label">
                          <Radio.RadioGroup
                            name={Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM + "|installationType"}
                            required
                            valueSelected={this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].installationType}
                            onChange={this.handleInputChange}
                          >
                            {['Yes','No'].map((radioOption) => (
                              <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption} id={Constants.WIFI_MARKETING_PRODUCT_ENUM + '-installationType' + radioOption} />
                            ))}
                          </Radio.RadioGroup>
                        </div>
                        {this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].installationType == 'Yes' &&
                        <div>
                          <p className="half-muted" style={{marginTop: "20px"}}><b>Note:</b> Installation services are
                            provided separately from this agreement. You will be contacted about installation pricing
                            after completing your registration.</p>
                          <hr/>
                          <h4>Will IT staff be available to assist with installation?</h4>
                          <p className="half-muted" style={{marginBottom: "20px"}}>Let us know if you have internal
                            staff available to offer guidance.</p>
                          <div className="form-group keep-label">
                            <Radio.RadioGroup
                              name={Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM + "|itStaffAvailable"}
                              required
                              valueSelected={this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].itStaffAvailable}
                              onChange={this.handleInputChange}
                            >
                              {['Yes', 'No'].map((radioOption) => (
                                <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption}
                                                 id={Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM + '-itStaffAvailable' + radioOption}/>
                              ))}
                            </Radio.RadioGroup>
                          </div>
                        </div>
                        }

                        {/*<hr/>*/}

                        {/*<h4>Content Library</h4>*/}
                        {/*<p>Please select a content library to be installed.</p>*/}

                        {/*<div className="form-group select-group">*/}
                          {/*<i className="fa fa-chevron-down"></i>*/}
                          {/*<SelectGroup name={Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM + "|contentLibrary"} id="contentLibrary"*/}
                                       {/*value={this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].contentLibrary}*/}
                                       {/*onChange={this.handleInputChange}*/}
                                       {/*required>*/}
                            {/*<option value=""></option>*/}
                            {/*<option value="Dentistry">Dentistry</option>*/}
                            {/*<option value="Automotive">Automotive</option>*/}
                            {/*<option value="Healthcare">Healthcare</option>*/}
                            {/*<option value="Entertainment">Entertainment</option>*/}
                          {/*</SelectGroup>*/}
                          {/*<label htmlFor="contentLibrary">Content Library</label>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </ValidationForm>
                  }

                  {this.state.selectedProducts.includes(Constants.ON_HOLD_MARKETING_PRODUCT_ENUM) &&

                  <ValidationForm
                    onSubmit={this.handleFormSubmit}
                    onErrorSubmit={this.handleFormError}
                    ref={this.onHoldMarketingForm} style={{marginBottom: "0"}}>
                    <h3 className="mb-4" style={{marginTop: "60px"}}>On-Hold Messaging Details:</h3>

                    <div className="card card--no-hover variable-top-margin">
                      <div className="mid">
                        <h4 className="mt-2">Number of Locations: &nbsp;&nbsp;<span className="purple">{this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].numLocations}</span>
                        </h4>
                        <p>Use the slider below to select the number of locations for your business.</p>
                        <div className='slider'>
                          <Slider
                            min={1}
                            max={Constants.MAX_LOCATIONS}
                            value={this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].numLocations}
                            onChange={(value) => this.handleSliderChange(Constants.ON_HOLD_MARKETING_PRODUCT_ENUM, 'numLocations', value)}
                          />
                        </div>
                        <hr/>
                        <h4>Message Production Frequency</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Please select how often you would like new on-hold messages for your business created.</p>
                        <div className="form-group keep-label">
                          <Radio.RadioGroup
                            name={Constants.ON_HOLD_MARKETING_PRODUCT_ENUM + "|messageProductFrequency"}
                            required
                            valueSelected={this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].messageProductFrequency}
                            onChange={this.handleInputChange}
                          >
                            {[
                              {'display':'Monthly', 'value': 'monthly'},
                              {'display': 'Quarterly', 'value': 'quarterly'},
                              {'display': 'Semi-Annually', 'value': 'semi-annually'}
                              ].map((radioOption) => (
                              <Radio.RadioItem label={radioOption.display} value={radioOption.value} key={radioOption.value}
                                               id={Constants.ON_HOLD_MARKETING_PRODUCT_ENUM + '-messageProductFrequency' + radioOption.value}/>
                            ))}
                          </Radio.RadioGroup>
                        </div>
                        <hr/>
                        <h4>Phone System Info</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Please enter the provider, make and model of your phone system (if known).</p>
                        <div className="form-row margin-top-main">
                          <div className="col-12">
                            <div className="form-group">
                              <TextInput name={Constants.ON_HOLD_MARKETING_PRODUCT_ENUM + "|phoneProvider"} id="phoneProvider" placeholder="Phone Provider"
                                         value={this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].phoneProvider}
                                         onChange={this.handleInputChange}
                              />
                              <label htmlFor="phoneProvider">Phone Provider</label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <TextInput name={Constants.ON_HOLD_MARKETING_PRODUCT_ENUM + "|phoneMake"} id="phoneMake" placeholder="Phone Equipment Make"
                                         value={this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].phoneMake}
                                         onChange={this.handleInputChange}
                              />
                              <label htmlFor="phoneMake">Phone Equipment Make</label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <TextInput name={Constants.ON_HOLD_MARKETING_PRODUCT_ENUM + "|phoneModel"} id="phoneModel" placeholder="Phone Equipment Model"
                                         value={this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].phoneModel}
                                         onChange={this.handleInputChange}
                              />
                              <label htmlFor="phoneModel">Phone Equipment Model</label>
                            </div>
                          </div>
                        </div>
                        <hr/>
                        <h4>Installation Services Required?</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Will you need Spectrio to provide installation services?</p>
                        <div className="form-group keep-label">
                          <Radio.RadioGroup
                            name={Constants.ON_HOLD_MARKETING_PRODUCT_ENUM + "|installationType"}
                            required
                            valueSelected={this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].installationType}
                            onChange={this.handleInputChange}
                          >
                            {['Yes','No'].map((radioOption) => (
                              <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption} id={Constants.ON_HOLD_MARKETING_PRODUCT_ENUM + '-installationType' + radioOption} />
                            ))}
                          </Radio.RadioGroup>
                        </div>
                        {this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].installationType == 'Yes' &&
                        <div>
                          <p className="half-muted" style={{marginTop: "20px"}}><b>Note:</b> Installation services are
                            provided separately from this agreement. You will be contacted about installation pricing
                            after completing your registration.</p>

                          <hr/>
                          <h4>Will IT staff be available to assist with installation?</h4>
                          <p className="half-muted" style={{marginBottom: "20px"}}>Let us know if you have internal
                            staff available to offer guidance.</p>
                          <div className="form-group keep-label">
                            <Radio.RadioGroup
                              name={Constants.ON_HOLD_MARKETING_PRODUCT_ENUM + "|itStaffAvailable"}
                              required
                              valueSelected={this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].itStaffAvailable}
                              onChange={this.handleInputChange}
                            >
                              {['Yes', 'No'].map((radioOption) => (
                                <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption}
                                                 id={Constants.ON_HOLD_MARKETING_PRODUCT_ENUM + '-itStaffAvailable' + radioOption}/>
                              ))}
                            </Radio.RadioGroup>
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  </ValidationForm>
                  }

                  {this.state.selectedProducts.includes(Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM) &&

                  <ValidationForm
                    onSubmit={this.handleFormSubmit}
                    onErrorSubmit={this.handleFormError}
                    ref={this.onPremiseMessagingForm} style={{marginBottom: "0"}}>
                    <h3 className="mb-4" style={{marginTop: "60px"}}>Overhead Music/Messaging:</h3>

                    <div className="card card--no-hover variable-top-margin">
                      <div className="mid">
                        <h4 className="mt-2">Number of Locations: &nbsp;&nbsp;<span className="purple">{this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].numLocations}</span>
                        </h4>
                        <p>Use the slider below to select the number of locations for your business.</p>
                        <div className='slider'>
                          <Slider
                            min={1}
                            max={Constants.MAX_LOCATIONS}
                            value={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].numLocations}
                            onChange={(value) => this.handleSliderChange(Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM, 'numLocations', value)}
                          />
                        </div>
                        <hr/>
                        <h4>Add Custom Messaging?</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Would you like only overhead music or both overhead music and messaging?</p>
                        <div className="form-group keep-label">
                          <Radio.RadioGroup
                            name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|messagingType"}
                            required
                            valueSelected={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].messagingType}
                            onChange={this.handleInputChange}
                          >
                            {[
                              {'display':'Music Only', 'value': 'music'},
                              {'display':'Music & Quarterly Message Production', 'value': 'quarterly'},
                              {'display':'Music & Monthly Message Production', 'value': 'monthly'},
                              ].map((radioOption) => (
                              <Radio.RadioItem label={radioOption.display} value={radioOption.value} key={radioOption.value} id={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + '-messagingType' + radioOption.value}  />
                            ))}
                          </Radio.RadioGroup>
                        </div>
                        <hr/>
                        <h4>Installation Services Required?</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Will you need Spectrio to provide installation services?</p>
                        <div className="form-group keep-label">
                          <Radio.RadioGroup
                            name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|installationType"}
                            required
                            valueSelected={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].installationType}
                            onChange={this.handleInputChange}
                          >
                            {['Yes','No'].map((radioOption) => (
                              <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption} id={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + '-installationType' + radioOption}  />
                            ))}
                          </Radio.RadioGroup>

                        </div>
                        {this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].installationType == 'Yes' &&
                        <div>
                          <p className="half-muted" style={{marginTop: "20px"}}><b>Note:</b> Installation services are
                            provided separately from this agreement. You will be contacted about installation pricing
                            after completing your registration.</p>

                          <hr/>
                          <h4>Will IT staff be available to assist with installation?</h4>
                          <p className="half-muted" style={{marginBottom: "20px"}}>Let us know if you have internal
                            staff available to offer guidance.</p>
                          <div className="form-group keep-label">
                            <Radio.RadioGroup
                              name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|itStaffAvailable"}
                              required
                              valueSelected={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].itStaffAvailable}
                              onChange={this.handleInputChange}
                            >
                              {['Yes', 'No'].map((radioOption) => (
                                <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption}
                                                 id={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + '-itStaffAvailable' + radioOption}/>
                              ))}
                            </Radio.RadioGroup>
                          </div>
                        </div>
                        }
                        <hr/>
                        <h4>Amplifier Location</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Your Amplifier (if you already have one) is in the same room
                          as which of the following?</p>

                        <div className="form-group keep-label">
                          <Radio.RadioGroup
                            name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|amplifierInfo"}
                            required
                            valueSelected={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].amplifierInfo}
                            onChange={this.handleInputChange}
                          >
                            {[
                              'Network Switch',
                              'Phone System',
                              'Both Network Switch & Phone System',
                              'Neither',
                              "I Don't Know This"
                            ].map((radioOption) => (
                              <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption} id={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + '-amplifierInfo' + radioOption} />
                            ))}
                          </Radio.RadioGroup>
                        </div>

                        {['Phone System', 'Both Network Switch & Phone System'].includes(this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].amplifierInfo) &&
                        <div>
                          <hr/>
                          <h4>Phone System Info</h4>
                          <p className="half-muted" style={{marginBottom: "20px"}}>Please enter the provider, make and model of your phone system (if known).</p>
                          <div className="form-row margin-top-main">
                            <div className="col-12">
                              <div className="form-group">
                                <TextInput name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|phoneProvider"} id="phoneProvider" placeholder="Phone Provider"
                                           value={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].phoneProvider}
                                           onChange={this.handleInputChange}
                                />
                                <label htmlFor="phoneProvider">Phone Provider</label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <TextInput name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|phoneMake"} id="phoneMake" placeholder="Phone Equipment Make"
                                           value={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].phoneMake}
                                           onChange={this.handleInputChange}
                                />
                                <label htmlFor="phoneMake">Phone Equipment Make</label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <TextInput name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|phoneModel"} id="phoneModel" placeholder="Phone Equipment Model"
                                           value={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].phoneModel}
                                           onChange={this.handleInputChange}
                                />
                                <label htmlFor="phoneModel">Phone Equipment Model</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        }

                        {['Network Switch', 'Both Network Switch & Phone System'].includes(this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].amplifierInfo) &&
                        <div>
                          <hr/>
                          <h4>Network Switch Info</h4>
                          <p className="half-muted" style={{marginBottom: "20px"}}>Please enter the make and model of your network switch (if known).</p>
                          <div className="form-row margin-top-main">
                            <div className="col-12">
                              <div className="form-group">
                                <TextInput name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|networkSwitchMake"} id="networkSwitchMake" placeholder="Network Switch Make"
                                           value={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].networkSwitchMake}
                                           onChange={this.handleInputChange}
                                           required
                                />
                                <label htmlFor="networkSwitchMake">Network Switch Make</label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <TextInput name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|networkSwitchModel"} id="networkSwitchModel" placeholder="Network Switch Model"
                                           value={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].networkSwitchModel}
                                           onChange={this.handleInputChange}
                                           required
                                />
                                <label htmlFor="networkSwitchModel">Network Switch Model</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        }

                        <hr/>
                        <h4>Connection Type</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>How will you be connecting to the audio player?</p>

                        <Radio.RadioGroup
                          name={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + "|connectionType"}
                          required
                          valueSelected={this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].connectionType}
                          onChange={this.handleInputChange}
                        >
                          {['LAN','WiFi', "I Don't Know This"].map((radioOption) => (
                            <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption} id={Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM + '-connectionType' + radioOption} />
                          ))}
                        </Radio.RadioGroup>
                      </div>
                    </div>
                  </ValidationForm>
                  }

                  {this.state.selectedProducts.includes(Constants.WIFI_MARKETING_PRODUCT_ENUM) &&

                  <ValidationForm
                    onSubmit={this.handleFormSubmit}
                    onErrorSubmit={this.handleFormError}
                    ref={this.wifiMarketingForm} style={{marginBottom: "0"}}>
                    <h3 className="mb-4" style={{marginTop: "60px"}}>WiFi Marketing:</h3>

                    <div className="card card--no-hover variable-top-margin">
                      <div className="mid">
                        <h4 className="mt-2">Number of Locations: &nbsp;&nbsp;<span className="purple">{this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].numLocations}</span>
                        </h4>
                        <p>Use the slider below to select the number of locations for your business.</p>
                        <div className='slider'>
                          <Slider
                            min={1}
                            max={Constants.MAX_LOCATIONS}
                            value={this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].numLocations}
                            onChange={(value) => this.handleSliderChange(Constants.WIFI_MARKETING_PRODUCT_ENUM, 'numLocations', value)}
                          />
                        </div>
                        <hr/>
                        <h4>Splash Page Update Frequency</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Please select how often you would like your splash page updated.</p>
                        <div className="form-group keep-label">
                          <Radio.RadioGroup
                            name={Constants.WIFI_MARKETING_PRODUCT_ENUM + "|updateFrequency"}
                            required
                            valueSelected={this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].updateFrequency}
                            onChange={this.handleInputChange}
                          >
                            {[{'display': 'Static', 'value': 'static'}, {'display': 'Quarterly', 'value': 'quarterly'}]
                              .map((radioOption) => (
                              <Radio.RadioItem label={radioOption.display} value={radioOption.value} key={radioOption.value} id={Constants.WIFI_MARKETING_PRODUCT_ENUM + '-updateFrequency' + radioOption.value} />
                            ))}
                          </Radio.RadioGroup>
                        </div>
                        <hr/>
                        <h4>WiFi System Info</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Please enter your WiFi provider, and router model (if known).</p>
                        <div className="form-row margin-top-main">
                          <div className="col-12">
                            <div className="form-group">
                              <TextInput
                                name={Constants.WIFI_MARKETING_PRODUCT_ENUM + "|internetProvider"} id="internetProvider" placeholder="Internet Provider"
                                value={this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].internetProvider}
                                onChange={this.handleInputChange}
                              />
                              <label htmlFor="internetProvider">Internet Provider</label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <TextInput
                                name={Constants.WIFI_MARKETING_PRODUCT_ENUM + "|routerMake"} id="routerMake" placeholder="Current Router Make"
                                value={this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].routerMake}
                                onChange={this.handleInputChange}
                              />
                              <label htmlFor="routerMake">Current Router</label>
                            </div>
                          </div>
                        </div>
                        <hr/>
                        <h4>Installation Services Required?</h4>
                        <p className="half-muted" style={{marginBottom: "20px"}}>Will you need Spectrio to provide installation services?</p>
                        <div className="form-group keep-label">
                          <Radio.RadioGroup
                            name={Constants.WIFI_MARKETING_PRODUCT_ENUM + "|installationType"}
                            required
                            valueSelected={this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].installationType}
                            onChange={this.handleInputChange}
                          >
                            {['Yes','No'].map((radioOption) => (
                              <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption} id={Constants.WIFI_MARKETING_PRODUCT_ENUM + '-installationType' + radioOption} />
                            ))}
                          </Radio.RadioGroup>
                        </div>
                        {this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].installationType == 'Yes' &&
                        <div>
                          <p className="half-muted" style={{marginTop: "20px"}}><b>Note:</b> Installation services are
                            provided separately from this agreement. You will be contacted about installation pricing
                            after completing your registration.</p>
                          <hr/>
                          <h4>Will IT staff be available to assist with installation?</h4>
                          <p className="half-muted" style={{marginBottom: "20px"}}>Let us know if you have internal
                            staff available to offer guidance.</p>
                          <div className="form-group keep-label">
                            <Radio.RadioGroup
                              name={Constants.WIFI_MARKETING_PRODUCT_ENUM + "|itStaffAvailable"}
                              required
                              valueSelected={this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].itStaffAvailable}
                              onChange={this.handleInputChange}
                            >
                              {['Yes', 'No'].map((radioOption) => (
                                <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption}
                                                 id={Constants.WIFI_MARKETING_PRODUCT_ENUM + '-itStaffAvailable' + radioOption}/>
                              ))}
                            </Radio.RadioGroup>
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  </ValidationForm>
                  }

                  { 1==2 && this.state.selectedProducts.includes(Constants.SCENT_MARKETING_PRODUCT_ENUM) &&
                  <ValidationForm
                    onSubmit={this.handleFormSubmit}
                    onErrorSubmit={this.handleFormError}
                    ref={this.scentMarketingForm} style={{marginBottom: "0"}}>
                    <h3 className="mb-4" style={{marginTop: "60px"}}>Scent Marketing:</h3>

                    <div className="card card--no-hover variable-top-margin">
                      <div className="mid">
                        <h4>Scent Type</h4>
                        <p style={{marginBottom: "20px"}}>Please select which type of scent you would like for your business.</p>
                        <div className="form-row margin-top-main">
                          <div className="col-12">
                            {/* Note: While this is currently just a text input, this will become a dropdown list of a bunch of scent types */}

                            <div className="form-group select-group">
                              <i className="fa fa-chevron-down"></i>
                              <SelectGroup name={Constants.SCENT_MARKETING_PRODUCT_ENUM + "|scentType"} id="scentType"
                                           value={this.state.productDetails[Constants.SCENT_MARKETING_PRODUCT_ENUM].scentType}
                                           onChange={this.handleInputChange}
                                           required>
                                <option value=""></option>
                                <option value="Cookies">Cookies</option>
                                <option value="Flowers">Flowers</option>
                              </SelectGroup>
                              <label htmlFor="scentType">Scent Type</label>
                            </div>
                          </div>
                        </div>
                        <hr/>
                        <h4>Air Handler Location</h4>
                        <p style={{marginBottom: "20px"}}>Where are your air handlers located?</p>
                        <div className="form-group keep-label">
                          <Radio.RadioGroup
                            name={Constants.SCENT_MARKETING_PRODUCT_ENUM + "|airHandlerLocation"}
                            required
                            valueSelected={this.state.productDetails[Constants.SCENT_MARKETING_PRODUCT_ENUM].airHandlerLocation}
                            onChange={this.handleInputChange}
                          >
                            {['Roof','Inside'].map((radioOption) => (
                              <Radio.RadioItem label={radioOption} value={radioOption} key={radioOption} id={Constants.SCENT_MARKETING_PRODUCT_ENUM + '-airHandlerLocation' + radioOption} />
                            ))}
                          </Radio.RadioGroup>
                        </div>
                        <hr/>
                        <h4>Number of Units:</h4>
                        <p style={{marginBottom: "20px"}}>How many units are at your location?</p>
                        <div className="form-row margin-top-main">
                          <div className="col-12">
                            <div className="form-group">
                              <TextInput
                                name={Constants.SCENT_MARKETING_PRODUCT_ENUM + "|numberUnits"} id="numberUnits" placeholder="Number of Units"
                                value={this.state.productDetails[Constants.SCENT_MARKETING_PRODUCT_ENUM].numberUnits}
                                onChange={this.handleInputChange}
                                required
                              />
                              <label htmlFor="numberUnits">Number of Units</label>
                            </div>
                          </div>
                        </div>
                        <hr/>
                        <h4>Location Size</h4>
                        <p style={{marginBottom: "20px"}}>What is the size of the space in square feet you want to
                          scent?</p>
                        <div className="form-row margin-top-main">
                          <div className="col-12">
                            <div className="form-group">
                              <TextInput
                                name={Constants.SCENT_MARKETING_PRODUCT_ENUM + "|squareFootage"} id="squareFootage" placeholder="Square Footage"
                                value={this.state.productDetails[Constants.SCENT_MARKETING_PRODUCT_ENUM].squareFootage}
                                onChange={this.handleInputChange}
                                type="number"
                                required
                              />
                              <label htmlFor="squareFootage">Square Footage</label>
                            </div>
                          </div>
                        </div>
                        <hr/>
                        <h4>Ceiling Height</h4>
                        <p style={{marginBottom: "20px"}}>What is the height of the ceiling at your location (in
                          feet)</p>
                        <div className="form-row margin-top-main">
                          <div className="col-12">
                            <div className="form-group">
                              <TextInput
                                name={Constants.SCENT_MARKETING_PRODUCT_ENUM + "|ceilingHeight"} id="ceilingHeight" placeholder="ceilingHeight"
                                value={this.state.productDetails[Constants.SCENT_MARKETING_PRODUCT_ENUM].ceilingHeight}
                                onChange={this.handleInputChange}
                                type="number"
                                required
                              />
                              <label htmlFor="ceilingHeight">Ceiling Height</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationForm>
                  }
                  { this.state.scheduleDemo &&
                    <div>
                      <h3 className="mb-4" style={{marginTop: "60px"}}>Schedule your FREE Consultation:</h3>
                      <p style={{marginBottom: "10px"}}>
                        In order to register for our <b className="purple-b">{this.state.scheduleDemoProducts}</b>, we'll need to do a brief call with you to discuss your business needs.
                      </p>

                      <iframe
                        src={"https://app.hubspot.com/meetings/demo-booking?embed=true&amp;parentHubspotUtk=9bc33f7260c164e4ff2239a9b650c40d&amp;parentPageUrl=https://www.spectrio.com/thank-you/" +
                        "&firstName=" + this.localCache.userDetails.firstName +
                        "&lastName=" + this.localCache.userDetails.lastName +
                        "&email=" + this.localCache.userDetails.email +
                        "&company=" + this.localCache.userDetails.organization
                        }
                        width="100%"
                        style={{minWidth: "312px", minHeight: "516px", height: "695px", border: "none"}}
                        title="Get a Demo"
                      ></iframe>
                    </div>
                  }
                </div>

                <ProductPricing data={this.localCache} onItemPricingUpdated={this.itemPricingUpdated}/>
                <div className="col-12 action-row">
                  <div className="form-group mt-4 mb-0">
                    <LinkContainer to="/" style={{marginRight: "5px"}}>
                      <button className="btn btn-lg">Back</button>
                    </LinkContainer>
                    <button className="btn btn-primary btn-lg w-110 no-margin"
                            data-testid="app-next-button"
                            onClick={this.completeStepTwo}>
                      <span className="">
                        Next
                      </span>
                      <span className="d-none">
                        <i className="fas fa-spinner fa-spin"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
   }
}
