import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { BrowserRouter as Router } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Content from './components/Content';
import ScrolltoTop from './components/ScrolltoTop';

function App() {
  return (
    <div className="app purple">
      <Router>
        <ScrolltoTop/>
        <Header />
        <Content />
        <Footer />
      </Router>
    </div>
  );
}

export default App;

