
export const DIGITAL_SIGNAGE_ITEM_ID = {
  1: 8220,
  2: 8221,
  3: 8222,
};

export const ON_PREMISE_MESSAGING_MUSIC_ITEM_ID = {
  1: 4636,
  2: 4637,
  3: 4638,
};


export const ON_PREMISE_MESSAGING_QUARTERLY_ITEM_ID = {
  1: 4460,
  2: 4460,
  3: 5231,
};

export const ON_PREMISE_MESSAGING_MONTHLY_ITEM_ID = {
  1: 2516,
  2: 2516,
  3: 5385,
};

export const WIFI_MARKETING_STATIC_ITEM_ID = {
  1: 5366,
  2: 5366,
  3: 5366,
}
export const WIFI_MARKETING_QUARTERLY_ITEM_ID = {
  1: 3569,
  2: 3569,
  3: 3569,
}

export const ON_HOLD_MARKETING_MONTHLY_ITEM_ID = {
  1: 813,
  2: 4596,
  3: 4600
};

export const ON_HOLD_MARKETING_QUARTERLY_ITEM_ID = {
  1: 4859,
  2: 4604,
  3: 4608
};

export const ON_HOLD_MARKETING_SEMI_ANNUALLY_ITEM_ID = {
  1: 825,
  2: 4612,
  3: 4616,
};

export const ACTIVATION_FEE_ITEM_ID = 1317;
export const DIGITAL_SIGNAGE_HARDWARE_ITEM_ID = process.env.REACT_APP_DIGITAL_SIGNAGE_HARDWARE_ITEM_ID;

export const DIGITAL_SIGNAGE_PRODUCT_ENUM = 'digital_signage';
export const INTERACTIVE_KIOSKS_PRODUCT_ENUM = 'interactive_kiosks';
export const ON_HOLD_MARKETING_PRODUCT_ENUM = 'on_hold_marketing';
export const ON_PREMISE_MESSAGING_PRODUCT_ENUM = 'on_premise_marketing';
export const SCENT_MARKETING_PRODUCT_ENUM = 'scent_marketing';
export const WIFI_MARKETING_PRODUCT_ENUM = 'wifi_marketing';


export const MAX_DISPLAYS = 20;
export const MAX_LOCATIONS = 10;

export const PRODUCT_INFO_LIST = [
  {
    productType: DIGITAL_SIGNAGE_PRODUCT_ENUM,
    name: 'Digital Signage',
    header: 'Engage',
    imgUrl: 'https://rrb9y3pc0lm4qd0v4tdsxqq9-wpengine.netdna-ssl.com/wp-content/uploads/2021/05/spectrio-engage.png',
    canPurchase: true,
  },
  {
    productType: INTERACTIVE_KIOSKS_PRODUCT_ENUM,
    name: 'Interactive Kiosks',
    header: 'Interact',
    imgUrl: 'https://rrb9y3pc0lm4qd0v4tdsxqq9-wpengine.netdna-ssl.com/wp-content/uploads/2021/06/shutterstock_1070876972-scaled.jpeg',
    canPurchase: false,
  },
  {
    productType: ON_HOLD_MARKETING_PRODUCT_ENUM,
    name: 'On-Hold Marketing',
    header: 'Inform',
    imgUrl: 'https://rrb9y3pc0lm4qd0v4tdsxqq9-wpengine.netdna-ssl.com/wp-content/uploads/2021/05/spectrio-inform.jpg',
    canPurchase: true,
  },
  {
    productType: ON_PREMISE_MESSAGING_PRODUCT_ENUM,
    name: 'Overhead Music/Messaging',
    header: 'Inspire',
    imgUrl: 'https://rrb9y3pc0lm4qd0v4tdsxqq9-wpengine.netdna-ssl.com/wp-content/uploads/2021/05/spectrio-inspire.jpg',
    canPurchase: true,
  },
  {
    productType: WIFI_MARKETING_PRODUCT_ENUM,
    name: 'WiFi Marketing',
    header: 'Connect',
    imgUrl: 'https://rrb9y3pc0lm4qd0v4tdsxqq9-wpengine.netdna-ssl.com/wp-content/uploads/2021/05/spectrio-connect.jpg',
    canPurchase: true,
  },
  {
    productType: SCENT_MARKETING_PRODUCT_ENUM,
    name: 'Scent Marketing',
    header: 'Enhance',
    imgUrl: 'https://rrb9y3pc0lm4qd0v4tdsxqq9-wpengine.netdna-ssl.com/wp-content/uploads/2021/06/shutterstock_1615214974-scaled.jpeg',
    canPurchase: false,
  },
]


let product_map = {}
let product_type_list = []
let purchaseableProducts = []

for(let x = 0; x<PRODUCT_INFO_LIST.length; x++) {
  const product = PRODUCT_INFO_LIST[x];
  product_map[product.productType] = product;
  product_type_list.push(product.productType);

  if(product.canPurchase)
    purchaseableProducts.push(product.productType);
}

export const PRODUCT_INFO_MAP = product_map;
export const PRODUCT_TYPES = product_type_list;
export const PURCHASEABLE_PRODUCTS = purchaseableProducts;
