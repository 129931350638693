import React, { Component } from 'react';
import iconEngage from "../img/icon-engage.png";
import iconInform from "../img/icon-inform.png";
import iconInspire from "../img/icon-inspire.png";
import iconConnect from "../img/icon-connect.png";
import iconEnhance from "../img/icon-enhance.png";
import iconInteract from "../img/icon-interact.png";
import * as Constants from "../constants";
import * as Utils from "../utils";
import Actions from '../actions/api';

export default class ProductPricing extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      billingTerms: props.data.billingTerms || {},
      selectedProducts: props.data.selectedProducts || [],
      productDetails: props.data.productDetails || {},
      itemPricing: props.data.itemPricing || {},
    }


    Actions.get('/item_pricing/').then( response => {

      this.setState({itemPricing : response})
      if(props.hasOwnProperty('onItemPricingUpdated'))
        props.onItemPricingUpdated(response);

    }).catch(error => {
      console.log(error)
    });
  }

  getContractLength = () => {
    const billingTerms = this.state.billingTerms || {};
    return parseInt(billingTerms.contractLength || 1);
  }

  getSavedPercentage = () => {
    return Utils.getSavedPercentage(this.state, this.getContractLength());
  }

  getSavedAmount = () => {
    return Utils.getSavedAmount(this.state, this.getContractLength());
  }


  getDigitalSignageCost = (contractLength) => {
    return Utils.getDigitalSignageCost(this.state, contractLength);
  }

  getOnHoldMarketingCost = (contractLength) =>{
    return Utils.getOnHoldMarketingCost(this.state, contractLength);
  }

  getOnPremiseMessagingCost = (contractLength) =>{
    return Utils.getOnPremiseMessagingCost(this.state, contractLength);
  }

  getWifiMarketingCost = (contractLength) =>{
    return Utils.getWifiMarketingCost(this.state, contractLength);
  }

  getActivationFee = () => {
    return Utils.getActivationFee(this.state);
  }

  getHardwareFee = () => {
    return Utils.getHardwareFee(this.state);
  }

  getTotalDue = (contractLength) => {
    return Utils.getTotalDue(this.state, contractLength);
  }

  getDigitalSignageNumDisplaysLabel(){
    const details = this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM];
    if(details.scheduleDemo)
      return 'Schedule Demo';

    let display = details.numDisplays + ' Screen';
    if(details.numDisplays > 1)
      display += 's';
    return display;
  }

  getDigitalSignageCostLabel(){
    const details = this.state.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM];

    if(details.scheduleDemo)
      return '$TBD';

    return '$' + this.getDigitalSignageCost(this.getContractLength()) + '/Month';
  }

  render () {

    if(Object.keys(this.state.itemPricing).length == 0)
      return (<div></div>);

    return (
        <div className="col-12 col-lg-4">
          <div className="sticky-lg">
            <div className="card card--no-hover sidebar-newsletter checkout-card pricing-sidebar-card">
              <div className="mid">
                <h5>Products:</h5>
                <table className="table">
                  <tbody>

                  {this.state.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM) &&
                  <tr>
                    <td>
                      <div className="product-line">
                        <div className="left">
                          <img src={iconEngage} alt="Spectrio Engage Logo"/>
                        </div>
                        <div className="right">
                          <b>Digital Signage</b>
                          <span
                            className="lower-label">{this.getDigitalSignageNumDisplaysLabel()}</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      {this.getDigitalSignageCostLabel()}
                    </td>
                  </tr>
                  }

                  {this.state.selectedProducts.includes(Constants.ON_HOLD_MARKETING_PRODUCT_ENUM) &&
                  <tr>
                    <td>
                      <div className="product-line">
                        <div className="left">
                          <img src={iconInform} alt="Spectrio Inform Logo"/>
                        </div>
                        <div className="right">
                          <b>On-Hold Messaging</b>
                          <span
                            className="lower-label">{this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].numLocations} Location{this.state.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].numLocations > 1 ? 's' : ''}</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      ${this.getOnHoldMarketingCost(this.getContractLength())}/Month
                    </td>
                  </tr>
                  }
                  {this.state.selectedProducts.includes(Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM) &&
                  <tr>
                    <td>
                      <div className="product-line">
                        <div className="left">
                          <img src={iconInspire} alt="Spectrio Inspire Logo"/>
                        </div>
                        <div className="right">
                          <b>Overhead Music/Messaging</b>
                          <span
                            className="lower-label">{this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].numLocations} Location{this.state.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].numLocations > 1 ? 's' : ''}</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      ${this.getOnPremiseMessagingCost(this.getContractLength())}/Month
                    </td>
                  </tr>
                  }
                  {this.state.selectedProducts.includes(Constants.WIFI_MARKETING_PRODUCT_ENUM) &&
                  <tr>
                    <td>
                      <div className="product-line">
                        <div className="left">
                          <img src={iconConnect} alt="Spectrio Connect Logo"/>
                        </div>
                        <div className="right">
                          <b>WiFi Marketing</b>
                          <span
                            className="lower-label">{this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].numLocations} Location{this.state.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].numLocations > 1 ? 's' : ''}</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      ${this.getWifiMarketingCost(this.getContractLength())}/Month
                    </td>
                  </tr>
                  }
                  {this.state.selectedProducts.includes(Constants.SCENT_MARKETING_PRODUCT_ENUM) &&
                  <tr>
                    <td>
                      <div className="product-line">
                        <div className="left">
                          <img src={iconEnhance} alt="Spectrio Enhance Logo"/>
                        </div>
                        <div className="right">
                          <b>Scent Marketing</b>
                          <span className="lower-label">Schedule Demo</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      $TBD
                    </td>
                  </tr>
                  }
                  {this.state.selectedProducts.includes(Constants.INTERACTIVE_KIOSKS_PRODUCT_ENUM) &&
                  <tr>
                    <td>
                      <div className="product-line">
                        <div className="left">
                          <img src={iconInteract} alt="Spectrio Interact Logo"/>
                        </div>
                        <div className="right">
                          <b>Interactive Kiosks</b>
                          <span className="lower-label">Schedule Demo</span>
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      $TBD
                    </td>
                  </tr>
                  }

                  {this.getHardwareFee() > 0 &&
                  <tr>
                    <td>
                      <div className="product-line">
                        <div className="right"><b>Hardware Fee</b></div>
                      </div>
                    </td>
                    <td className="text-right">${this.getHardwareFee().toLocaleString()}</td>
                  </tr>
                  }

                  {this.getActivationFee() > 0 &&
                    <tr>
                      <td>
                        <div className="product-line">
                          <div className="right"><b>Activation Fee</b></div>
                        </div>
                      </td>
                      <td className="text-right">${this.getActivationFee().toLocaleString()}</td>
                    </tr>
                  }

                  </tbody>
                </table>
                <div className="lower">
                      <span className="upper-label">
                        Total Due Today:
                      </span>
                  <div className="price">
                    {this.getTotalDue(this.getContractLength()).toLocaleString('en-US', {style: "currency", currency: 'USD'})}
                  </div>

                  {
                    this.getContractLength() < 3 && this.getSavedPercentage() > 0 &&
                    <span className="billing-notice">
                  Save {this.getSavedPercentage()}% with a 3 year contract!
                </span>
                  }

                  {
                    this.getContractLength() == 3 && this.getSavedAmount() > 0 &&
                    <span className="billing-notice">
                  You saved ${this.getSavedAmount()}.00 with a 3 year contract!
                </span>
                  }
                </div>
              </div>
            </div>
            <div className="help-block">
              <h5 className="mb-3">Questions?</h5>
              <p>You can always <a href="https://www.spectrio.com/demo/">Schedule a Demo</a> or call us today at <a
                href="tel:tel:18005844653" title="Spectrio Phone Number">1-800-584-4653</a></p>
            </div>
          </div>
        </div>
      )
   }
}
