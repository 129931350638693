import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Home from '../views/Home';
import Step2 from '../views/Step2';
// import Step2Full from '../views/Step2';
import Step3 from '../views/Step3';
import Thanks from '../views/Thanks';
import { Switch, Route } from "react-router-dom";
import './Content.scss';

class Content extends Component {
  render () {
    return (
      <main className={'main main--' + this.props.location.pathname.replace(/\//g, '')}>
      <Route render={({ location }) => {

        return (
          <Switch location={location}>
            <Route exact path="/" component={Home}/>
            <Route path="/step-2" component={Step2}/>
            <Route path="/step-3" component={Step3}/>
            <Route path="/thank-you" component={Thanks}/>
          </Switch>
        )
      }}/>
      </main>
    )
  }
}

export default withRouter(Content);
