
const Qs = require('qs');
const request = require('request-json');

const baseUrl = process.env.REACT_APP_API_URL;


const client = request.createClient(baseUrl);

const JsonFetch = (options) => {
  // const cookies = Cookie.parse(document.cookie);
  let url = options.url;

  if (options.query) {
    url += '?' + Qs.stringify(options.query);
  }

  const handleResponse = response => {

    if (!response) {
      return {};
    } else if (response.body && response.body.error) {
      if (response.body.error.extra) {
        if (response.body.error.extra.detail) {
          throw response.body.error.extra.detail;
        } else {
          throw response.body.error.extra;
        }
      } else {
        throw response.body.error.message;
      }
    } else if (response.body) {
      return response.body;
    } else {
      return {}; //throw 'no response';
    }
  };


  if (options.method === 'POST') {
    return client.post(url, options.data).then(handleResponse);
  } else if (options.method === 'GET') {
    return client.get(url).then(handleResponse);
  } else if (options.method === 'PUT') {
    return client.put(url, options.data).then(handleResponse);
  } else if (options.method === 'PATCH') {
    return client.patch(url, options.data).then(handleResponse);
  } else if (options.method === 'DELETE') {
    return client.delete(url).then(handleResponse);
  }
  return null;
};

class Actions {

  static async get(url, query) {
    const request = { method: 'GET', url, query };

    return await JsonFetch(request);
  }

  static async put(url, data) {

    const request = { method: 'PUT', url, data };

    return await JsonFetch(request);
  }

  static async patch(url, data) {

    const request = { method: 'PATCH', url, data };

    return await JsonFetch(request);
  }

  static async post(url, data) {

    const request = { method: 'POST', url, data };

    return await JsonFetch(request);
  }

  static async delete(url, query) {

    const request = { method: 'DELETE', url, query };

    return await JsonFetch(request);
  }
}

export default Actions;
