import * as Constants from "../constants";

const CURRENT_CACHE_VERSION = 4;
const CACHE_KEY = 'cache'


export function getCacheVersion(){
  return CURRENT_CACHE_VERSION;
}

export function getLocalCache(){
  let cached = JSON.parse(window.localStorage.getItem(CACHE_KEY) || '{}');
  const cachedVersion = cached.version;
  if(cachedVersion !== CURRENT_CACHE_VERSION){
    //for now just clear it, probably some upgrade path could take though
    cached = {version: CURRENT_CACHE_VERSION}
  }

  return cached;
}

export function clearCache(){
  window.localStorage.removeItem(CACHE_KEY);
}


export function disableNumberScroll(){
  document.addEventListener("wheel", function(event){
    if(document.activeElement.type === "number"){
      const element = document.activeElement;
      element.blur();
      // setTimeout(() => {
      //   element.focus();
      // }, 0);
    }
  });
}

export function scrollToElement(element){
  const yOffset = -100;
  const top = element.getBoundingClientRect ? element.getBoundingClientRect().top : element.top;
  const y = top + window.pageYOffset + yOffset;

  window.scrollTo({top: y, behavior: 'smooth'});
}


export function storeLocalCache(cache){
  window.localStorage.setItem('cache', JSON.stringify(cache));
}

export function getDefaultAddress(){
  return {
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    numDigitalSignageDevices: 0,
  };
}

export function getExpirationMonthDisplayValue(value){
  return String(value).padStart(2, '0');
}

export function getDefaultExpirationMonth(){
  return getExpirationMonthDisplayValue(new Date().getMonth() + 1)
}

export function getExpirationMonthValues(){
  let values = []

  for(let x = 1; x <13; x++){
    values.push(getExpirationMonthDisplayValue(x));
  }

  return values;
}

export function getExpirationYearValues(){
  let year = new Date().getFullYear();
  let values = [];

  for(let x = 0; x<20; x++){
    values.push(year + x);
  }

  return values;
}

export function getStateAbbreviations(){
  return [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY'
  ];
}


export function getSavedPercentage(cacheData, contractLength, compareContractLength) {
  if(!compareContractLength)
    compareContractLength = 3;

  const totalDue = this.getTotalDue(cacheData, contractLength);
  const threeYearDue = this.getTotalDue(cacheData, compareContractLength);

  return Math.round((totalDue - threeYearDue) / totalDue * 100);
}

export function getSavedAmount(cacheData, contractLength) {
  return Math.round(this.getTotalDue(cacheData, 1) - this.getTotalDue(cacheData, contractLength));
}


export function getDigitalSignageCost(cacheData, contractLength){

  if(cacheData.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM) && cacheData.itemPricing) {

    const productDetails = cacheData.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM];
    if(!productDetails.scheduleDemo) {
      const item_id = Constants.DIGITAL_SIGNAGE_ITEM_ID[contractLength];
      return productDetails.numDisplays * cacheData.itemPricing[item_id];
    }
  }

  return 0;
}

export function getOnHoldMarketingCost(cacheData, contractLength){
  if(cacheData.selectedProducts.includes(Constants.ON_HOLD_MARKETING_PRODUCT_ENUM) && cacheData.itemPricing) {
    const details = cacheData.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM];
    let frequency = details.messageProductFrequency || '';

    let itemLookup = Constants.ON_HOLD_MARKETING_MONTHLY_ITEM_ID;
    if (frequency === 'quarterly')
      itemLookup = Constants.ON_HOLD_MARKETING_QUARTERLY_ITEM_ID
    else if(frequency === 'semi-annually')
      itemLookup = Constants.ON_HOLD_MARKETING_SEMI_ANNUALLY_ITEM_ID;

    const itemId = itemLookup[contractLength];
    return details.numLocations * cacheData.itemPricing[itemId];
  }

  return 0;
}

export function getOnPremiseMessagingCost(cacheData, contractLength){
  if(cacheData.selectedProducts.includes(Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM) && cacheData.itemPricing) {
    const details = cacheData.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM];

    let price = cacheData.itemPricing[Constants.ON_PREMISE_MESSAGING_MUSIC_ITEM_ID[contractLength]];

    if(details.messagingType === 'quarterly')
      price += cacheData.itemPricing[Constants.ON_PREMISE_MESSAGING_QUARTERLY_ITEM_ID[contractLength]]
    else if(details.messagingType === 'monthly')
      price += cacheData.itemPricing[Constants.ON_PREMISE_MESSAGING_MONTHLY_ITEM_ID[contractLength]]

    return details.numLocations * price;
  }

  return 0;
}

export function getWifiMarketingCost(cacheData, contractLength){
  if(cacheData.selectedProducts.includes(Constants.WIFI_MARKETING_PRODUCT_ENUM) && cacheData.itemPricing) {
    const details = cacheData.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM];

    const itemLookup = details.updateFrequency !== 'static' ? Constants.WIFI_MARKETING_QUARTERLY_ITEM_ID : Constants.WIFI_MARKETING_STATIC_ITEM_ID;
    const itemId = itemLookup[contractLength];

    return details.numLocations * cacheData.itemPricing[itemId];
  }

  return 0;
}

export function getHardwareFee(cacheData){
  if(!cacheData.itemPricing)
    return 0;

  let hardwareFee = 0;

  if(cacheData.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM)) {
    if (!cacheData.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].scheduleDemo) {
      const numDisplays = cacheData.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numDisplays || 0;

      hardwareFee += numDisplays * cacheData.itemPricing[Constants.DIGITAL_SIGNAGE_HARDWARE_ITEM_ID];
    }
  }

  return hardwareFee;

}

export function getActivationFee(cacheData){
  if(!cacheData.itemPricing)
    return 0;
  let activationCount = 0;

  if(cacheData.selectedProducts.includes(Constants.WIFI_MARKETING_PRODUCT_ENUM))
    activationCount +=  cacheData.productDetails[Constants.WIFI_MARKETING_PRODUCT_ENUM].numLocations || 0;

  if(cacheData.selectedProducts.includes(Constants.ON_HOLD_MARKETING_PRODUCT_ENUM))
    activationCount +=  cacheData.productDetails[Constants.ON_HOLD_MARKETING_PRODUCT_ENUM].numLocations || 0;

  if(cacheData.selectedProducts.includes(Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM))
    activationCount +=  cacheData.productDetails[Constants.ON_PREMISE_MESSAGING_PRODUCT_ENUM].numLocations || 0;

  if(cacheData.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM)) {
    if (!cacheData.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].scheduleDemo) {
      activationCount += cacheData.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numLocations || 0;
    }
  }

  return activationCount * cacheData.itemPricing[Constants.ACTIVATION_FEE_ITEM_ID];
}

export function getTotalDue(cacheData, contractLength){

  return getDigitalSignageCost(cacheData, contractLength)
    + getOnHoldMarketingCost(cacheData, contractLength)
    + getOnPremiseMessagingCost(cacheData, contractLength)
    + getWifiMarketingCost(cacheData, contractLength)
    + getActivationFee(cacheData)
    + getHardwareFee(cacheData);
}

export function getCookie(cookieName) {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [key,value] = el.split('=');
    cookie[key.trim()] = value;
  })
  return cookie[cookieName];
}

export function getUtmCampaignValues(){
  let queryParams = {};

  const utmCookieValue = getCookie('__gtm_campaign_url');

  if(utmCookieValue != null){
    const urlParts = decodeURIComponent(utmCookieValue).split('?');

    if(urlParts.length > 1) {

      const params = urlParts[1].split('&');

      for(let x = 0; x<=params.length; x++){
        if(params[x] == null)
          continue;

        const paramValues = params[x].split('=');
        if(paramValues.length < 2)
          continue;

        queryParams[paramValues[0]] = paramValues[1];
      }
    }
  }

  return queryParams;
}

