import React, { Component } from 'react';

import { Alert } from 'react-bootstrap';
import * as Constants from '../constants';
import Stepper from 'react-stepper-horizontal';
import { ValidationForm, TextInput, SelectGroup, Checkbox, Radio } from "react-bootstrap4-form-validation";
import { LinkContainer } from 'react-router-bootstrap';
import * as Utils from "../utils";
import ProductPricing from "../components/ProductPricing";
import Actions from '../actions/api';

export default class Home extends Component {
  constructor(props, context) {
    super(props, context)

    Utils.disableNumberScroll();

    this.localCache = Utils.getLocalCache();
    this.localCache.orderSubmitted = false;

    if(!this.localCache.stepTwoCompleted) {
      this.state = {
        redirecting: true,
      }
      window.location.assign('/step-2');
      return;
    }

    if(!this.localCache.billingAddress) {
      this.localCache.billingAddress = Utils.getDefaultAddress();
    }

    if(!this.localCache.shippingAddresses) {
      let firstShippingAddress = Utils.getDefaultAddress()
      firstShippingAddress.useBillingAddress = true;
      this.localCache.shippingAddresses = [firstShippingAddress];
    }

    if(!this.localCache.billingTerms){
      this.localCache.billingTerms = {
        frequency: '',
        type: '',
      }
    }

    this.firstShippingAddressProductDetailRef = React.createRef();

    //setup form refs for validation
    this.creditCardRef = React.createRef();
    this.billingAddressRef = React.createRef();
    this.billingDetailsRef = React.createRef();

    this.validatingIndex = 0;
    this.forms = [
      this.creditCardRef,
      this.billingAddressRef,
    ]

    this.specifyLocationProducts = [];
    this.shippingFormRefs = this.setupShippingAddresses();
    this.forms.push.apply(this.forms, this.shippingFormRefs);
    this.forms.push(this.billingDetailsRef);


    this.state = {
      creditCardInfo: {
        name: '',
        number: '',
        expirationMonth: Utils.getDefaultExpirationMonth(),
        expirationYear: new Date().getFullYear(),
        ccv: '',
      },
      billingAddress: this.localCache.billingAddress,
      shippingAddresses: this.localCache.shippingAddresses,
      billingTerms: this.localCache.billingTerms,
      acceptTerms: false,
      alertMessage: '',
      processingSubmit: false,
      selectedProducts: this.localCache.selectedProducts,
      productDetails: this.localCache.productDetails,
      itemPricing: this.localCache.itemPricing,

    }
  };

  getRemainingDigitalSignageDevicesDisplay = () => {
    const remaining = this.getRemainingDigitalSignageDevices();
    if(remaining > 0)
      return remaining;

    return 0;
  }

  getRemainingDigitalSignageDevices = () => {
    if(!this.localCache.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM))
      return 0;

    const productDetails = this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM];

    if(productDetails.scheduleDemo)
      return 0;

    let numDisplays = productDetails.numDisplays;
    let assignedDisplays = 0;


    for(let x = 0; x<this.state.shippingAddresses.length; x++){
      assignedDisplays += parseInt(this.state.shippingAddresses[x].numDigitalSignageDevices || 0);
    }

    return numDisplays - assignedDisplays;

  }

  setupShippingAddresses = () => {
    let maxLocations = 0;
    let productLocations = {}
    for(let x = 0; x<Constants.PRODUCT_INFO_LIST.length; x++) {
      const productType = Constants.PRODUCT_INFO_LIST[x].productType;

      //only check if the product was selected
      if(!this.localCache.selectedProducts.includes(productType))
        continue;

      const productDetails = this.localCache.productDetails[productType] || {}

      if(productDetails.scheduleDemo)
        continue;

      const numLocations = productDetails.numLocations || 0;

      //store any product with a number of locations set
      if(numLocations > 0) {
        productLocations[productType] = numLocations;
      }

      if (numLocations > maxLocations) {
        maxLocations = numLocations;
      }
    }

    for(const [productType, numLocations] of Object.entries(productLocations)){
      //see if any products have less locations than the max location that is not digital signage, which is handled separately
      if(numLocations < maxLocations && productType != Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM) {
        this.specifyLocationProducts.push(productType);
      }
    }

    //always need at least one shipping address?
    if(maxLocations <= 0)
      maxLocations = 1;

    let refs = [React.createRef()];

    //remove any that we do not need
    while(this.localCache.shippingAddresses.length > maxLocations){
      this.localCache.shippingAddresses.pop();
    }

    while(refs.length < maxLocations){
      refs.push(React.createRef());

      if(this.localCache.shippingAddresses.length < refs.length){
        this.localCache.shippingAddresses.push(Utils.getDefaultAddress());
      }
    }

    //reset default values if product is not selected
    //use when they toggle off a product or change the number of locations
    Constants.PRODUCT_TYPES.forEach((productType) => {

      const productSelected = this.localCache.selectedProducts.includes(productType);

      let forceValue = null;
      let fieldName = productType;

      //digital signage has an actual count
      if(productType == Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM){
        if(!productSelected){
          forceValue = 0;
          fieldName = 'numDigitalSignageDevices';
        }
      }else{

        if(productSelected && !this.specifyLocationProducts.includes(productType)) {
          forceValue = true;
        }else if(!productSelected){
          forceValue = false;
        }
      }

      if(forceValue == null)
        return;

      for(let x = 0; x<this.localCache.shippingAddresses.length; x++){
        this.localCache.shippingAddresses[x][fieldName] = forceValue;
      }

    });


    Utils.storeLocalCache(this.localCache);

    return refs;
  }


  updateShippingAddressToggle = (e, index) => {
    const value = !(this.state.shippingAddresses[index][e.target.name] || false);
    this.updateShippingAddressValue(index, e.target.name, value);

  }

  updateShippingAddress = (e, index) => {
    this.updateShippingAddressValue(index, e.target.name, e.target.value);
  }

  updateShippingAddressValue = (index, key, value) => {
    this.setAlertMessage('');
    let shippingAddresses = this.state.shippingAddresses;
    shippingAddresses[index][key] = value;
    this.setState({shippingAddresses: shippingAddresses});

    //update local cache
    this.localCache.shippingAddresses = shippingAddresses;
    Utils.storeLocalCache(this.localCache);

  }

  updateAcceptTerms = (e) => {
    const acceptTerms = !this.state.acceptTerms
    this.setState({acceptTerms: acceptTerms});
  }

  updateValue = (objectName, field, value) => {
    this.setAlertMessage('');
    let obj = this.state[objectName];
    obj[field] = value;

    this.setState({[objectName]: obj});

    //do not cache the credit card info, even locally
    if(objectName != 'creditCardInfo'){
      this.localCache[objectName] = obj;
      //for now force them to click next in step two after changing a value to force validation
      //until I can think of a better way
      Utils.storeLocalCache(this.localCache);
    }
  };

  handleInputChange = (e) => {
    const split = e.target.name.split('|');

    const objectName = split[0];
    const field = split[1];

    this.updateValue(objectName, field, e.target.value);
  };

  setAlertMessage = (msg) => {
    this.setState({
      alertMessage: msg
    })
  }

  submitBillingInfo = (e) => {
    this.setAlertMessage('');
    e.preventDefault();

    //ensure all of the forms pass validation
    this.validatingIndex = -1;
    this.validateNextForm(e);

  }

  validateNextForm = (e) => {
    this.validatingIndex++;

    if(this.validatingIndex >= this.forms.length){

      const validationError = this.validateShippingAddressProductDetails();
      if(validationError != null){
        Utils.scrollToElement(this.firstShippingAddressProductDetailRef.current);
        this.setAlertMessage(validationError);
        return;
      }

      const today = new Date();

      if(parseInt(this.state.creditCardInfo.expirationYear) == today.getFullYear() && parseInt(this.state.creditCardInfo.expirationMonth) < today.getMonth() + 1){
        this.setAlertMessage('Please use a credit card that is not expired.');
        return;
      }


      let creditCardInfo = this.state.creditCardInfo;
      creditCardInfo.expirationDate = this.state.creditCardInfo.expirationYear + '-' + this.state.creditCardInfo.expirationMonth;

      let apiRequestData = {
        'user': this.localCache.userDetails,
        'billingAddress': this.localCache.billingAddress,
        'billingTerms': this.localCache.billingTerms,
        'shippingAddresses': this.localCache.shippingAddresses,
        'creditCard': creditCardInfo,
        'productDetails': this.localCache.productDetails,
        'selectedProducts': this.localCache.selectedProducts,
        'uuid': this.localCache.uuid,
      }


      //if there is only one shipping address, assign the total number of displays to it
      if(apiRequestData.shippingAddresses.length == 1){
        if(this.localCache.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM)
          && !this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].scheduleDemo){
          apiRequestData.shippingAddresses[0].numDigitalSignageDevices = this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numDisplays;
        }else{
          apiRequestData.shippingAddresses[0].numDigitalSignageDevices = 0;
        }
      }

      this.setState({processingSubmit : true})
      this.setAlertMessage('');

      Actions.post('/submit/', apiRequestData).then( response => {

        if(response.hasOwnProperty('error_message')){
          this.setAlertMessage(response['error_message']);
          this.setState({processingSubmit : false})
          return;
        }

        this.localCache.orderSubmitted = true;
        Utils.storeLocalCache(this.localCache)
        window.location.assign('/thank-you');
      }).catch(error => {
        console.log(error);
        this.setAlertMessage('There was a problem processing your request. A representative will reach out to you shortly.');
        this.setState({processingSubmit : false})
      });

      return;
    }

    const form = this.forms[this.validatingIndex];
    form.current.resetValidationState();
    form.current.handleSubmit(e);
  }



  validateShippingAddressProductDetails = () => {
    if(this.state.shippingAddresses.length > 1) {
      const remainingDevices = this.getRemainingDigitalSignageDevices();
      if (remainingDevices > 0) {
        return 'You still have ' + remainingDevices + ' digital signage devices to assign to a shipping location';
      } else if (remainingDevices < 0) {
        return 'You have assigned ' + (remainingDevices * -1) + ' too many digital signage devices to shipping locations';
      }
    }

    for(let x = 0; x<this.specifyLocationProducts.length; x++){
      const productType = this.specifyLocationProducts[x];

      const expectedCount = this.localCache.productDetails[productType].numLocations;
      let selectedCount = 0;
      this.state.shippingAddresses.forEach((shippingAddress) => {
        if(shippingAddress[productType] == true)
          selectedCount++;
      });

      if(selectedCount > expectedCount){
        const tooMany = selectedCount - expectedCount;
        return 'You have selected ' + tooMany + ' too many locations to install ' + Constants.PRODUCT_INFO_MAP[productType].name;
      }else if(selectedCount < expectedCount) {
        const remaining = expectedCount - selectedCount;
        return 'You must select ' + remaining + ' more location' + (remaining == 1 ? '' : 's') + ' to install ' + Constants.PRODUCT_INFO_MAP[productType].name;
      }
    }

    return null;
  }

  handleFormError = (e, formData, errorInputs) => {
    //hacky way to reset the for, is-valid state that seemed to get messed up by calling
    //handleSubmit directly
    const inputs = this.forms[this.validatingIndex].current.inputs;
    for( const key in inputs) {
      inputs[key].clearError();
      inputs[key].checkError();
    }
  }

  handleFormSubmit = (e, formData, inputs) => {

    this.forms[this.validatingIndex].current.resetValidationState();

    this.validateNextForm(e);

  }

  getSavedPercentageDisplay = (contractLength) => {
    const percentage = Utils.getSavedPercentage(this.state, 1, contractLength);
    if(percentage > 0)
      return 'Save ' + percentage + '%';
    return '';
  }

  itemPricingUpdated = (itemPricing) => {
    this.localCache.itemPricing = itemPricing;
    Utils.storeLocalCache(this.localCache);

    this.setState({itemPricing: itemPricing})
  };

  render () {

    if(this.state.redirecting)
      return (<div></div>);

      const termsLabel = [];
      termsLabel.push(<span>I agree to the Spectrio <a href="https://www.spectrio.com/terms-and-conditions/" className="link-blue">Terms & Conditions</a></span>);

      return (
        <div className="registration-inner-content">
          {this.state.alertMessage &&
            <Alert variant="danger" onClose={() => this.setAlertMessage('')} dismissible>
             {this.state.alertMessage}
            </Alert>
          }
          <div className="bs-form">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="stepper">
                    <Stepper
                      steps={ [
                        {title: 'Customer Info', href: "/"},
                        {title: 'Plan Details', href: "/step-2"},
                        {title: 'Payment Info'}
                      ] }
                      activeStep={ 2 }
                      completeColor="#44218d"
                      activeColor="#44218d"
                      defaultBarColor="#ddd"
                      completeBarColor="#44218d"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                    <h3 className="mt-4 mb-4">Payment Details:</h3>

                    <div className="card card--no-hover sidebar-newsletter variable-top-margin">
                      <div className="mid">
                        <ValidationForm
                          onSubmit={this.handleFormSubmit}
                          onErrorSubmit={this.handleFormError}
                          ref={this.creditCardRef}
                          style={{marginBottom: "0"}}
                        >
                          <h4>Credit Card Info</h4>
                          <p className="half-muted">Please enter your credit card details below.</p>
                            <div className="form-group">
                              <TextInput
                                name="creditCardInfo|name"
                                type="text"
                                placeholder="Cardholder Name"
                                onChange={this.handleInputChange}
                                required
                              />
                              <label htmlFor="creditCardInfo|name">Cardholder Name</label>
                            </div>
                            <div className="form-group">
                              <TextInput
                                name="creditCardInfo|number"
                                type="tel"
                                placeholder="Card Number"
                                onChange={this.handleInputChange}
                                required
                              />
                              <label htmlFor="creditCardInfo|number">Card Number</label>
                            </div>
                            <div className="form-row">
                              <div className="col-12 col-lg-4">
                                {/*<div className="form-group">
                                  <TextInput
                                    name="creditCardInfo|expirationDate"
                                    type="month"
                                    placeholder="Expiration Date"
                                    min={new Date().getFullYear() + '-' + (new Date().getMonth()+1)}
                                    onChange={this.handleInputChange}
                                    required
                                  />
                                  <label htmlFor="ccv">Expiration Date</label>
                                </div>*/}
                                <div className="form-group select-group has-value">
                                  <SelectGroup name="creditCardInfo|expirationMonth"
                                               value={this.state.creditCardInfo.expirationMonth}
                                               onChange={this.handleInputChange}
                                               placeholder="Expiration Month"
                                               required
                                  >
                                    {Utils.getExpirationMonthValues().map((option) => (
                                        <option value={option}>{option}</option>
                                    ))}
                                  </SelectGroup>
                                  <label htmlFor="billingAddress|state">Expiration Month</label>
                                </div>
                              </div>
                              <div className="col-12 col-lg-4">
                                <div className="form-group select-group has-value">
                                  <SelectGroup name="creditCardInfo|expirationYear"
                                               value={this.state.creditCardInfo.expirationYear}
                                               onChange={this.handleInputChange}
                                               placeholder="Expiration Year"
                                               required
                                  >

                                    <option value=""></option>
                                    {Utils.getExpirationYearValues().map((option) => (
                                        <option value={option}>{option}</option>
                                    ))}
                                  </SelectGroup>
                                  <label htmlFor="billingAddress|state">Expiration Year</label>
                                </div>
                              </div>
                              <div className="col-12 col-lg-4">
                                <div className="form-group">
                                  <TextInput
                                    name="creditCardInfo|ccv"
                                    type="tel"
                                    placeholder="CCV"
                                    onChange={this.handleInputChange}
                                    required
                                  />
                                  <label htmlFor="creditCardInfo|ccv">CCV</label>
                                </div>
                              </div>
                            </div>
                          </ValidationForm>
                          <hr />

                          <ValidationForm
                            onSubmit={this.handleFormSubmit}
                            onErrorSubmit={this.handleFormError}
                            ref={this.billingAddressRef}
                            style={{marginBottom: "0"}}
                          >
                            <h4>Billing Address</h4>
                            <p className="half-muted">Please provide the details of your billing address.</p>
                            <div className="form-group">
                              <TextInput
                                name="billingAddress|street"
                                type="text"
                                placeholder="Street Address 1"
                                onChange={this.handleInputChange}
                                value={this.state.billingAddress.street}
                                required
                              />
                              <label htmlFor="ccv">Address Line 1</label>
                            </div>
                            <div className="form-group">
                              <TextInput
                                name="billingAddress|street2"
                                type="text"
                                placeholder="Street Address 2"
                                onChange={this.handleInputChange}
                                value={this.state.billingAddress.street2}
                              />
                              <label htmlFor="ccv">Address Line 2</label>
                            </div>
                            <div className="form-row">
                              <div className="col-12">
                                <div className="form-group">
                                  <TextInput
                                    name="billingAddress|city"
                                    type="text"
                                    placeholder="City"
                                    onChange={this.handleInputChange}
                                    value={this.state.billingAddress.city}
                                    required
                                  />
                                  <label htmlFor="billingAddress|city">City</label>
                                </div>
                              </div>
                              <div className="col-12 col-lg-8">
                                <div className={`form-group select-group ${this.state.billingAddress.state != '' ? 'has-value' : ''}`}>
                                  <SelectGroup name="billingAddress|state"
                                               value={this.state.billingAddress.state}
                                               onChange={this.handleInputChange}
                                               placeholder="State"
                                               required
                                  >

                                    <option value=""></option>
                                    {Utils.getStateAbbreviations().map((option) => (
                                        <option value={option}>{option}</option>
                                    ))}
                                  </SelectGroup>
                                  <label htmlFor="billingAddress|state">State</label>
                                </div>
                              </div>
                              <div className="col-12 col-lg-4">
                                <div className="form-group">
                                  <TextInput
                                    name="billingAddress|zip"
                                    type="text"
                                    placeholder="Zip Code"
                                    onChange={this.handleInputChange}
                                    value={this.state.billingAddress.zip}
                                    required
                                  />
                                  <label htmlFor="billingAddress|zip">Zip Code</label>
                                </div>
                              </div>
                            </div>

                          </ValidationForm>
                          <hr />


                        {this.state.shippingAddresses.map((shippingAddress, index) => (
                        <div key={'shippingAddress' + index}>
                          <ValidationForm
                            onSubmit={this.handleFormSubmit}
                            onErrorSubmit={this.handleFormError}
                            ref={this.shippingFormRefs[index]}
                            style={{marginBottom: "0"}}>
                            <h4>Shipping Address {index == 0 ? "(Primary Location)" : "(Location #" + (index+1) + ')'}</h4>
                            <p className="half-muted">Please provide the details of your shipping address.</p>
                            {index == 0 &&
                            <Checkbox
                              name="useBillingAddress"
                              id="billingIsShipping"
                              label="Shipping Address is the same as Billing Address"
                              onChange={(e) => {this.updateShippingAddressToggle(e, index)}}
                              value={this.state.shippingAddresses[index].useBillingAddress}
                            />
                            }

                            {(index != 0 || !this.state.shippingAddresses[0].useBillingAddress) &&
                              <div>
                                <div style={{paddingTop: "20px"}}>
                                  <div className="form-group">
                                    <TextInput
                                      name="street"
                                      type="text"
                                      placeholder="Street Address 1"
                                      onChange={(e) => {this.updateShippingAddress(e, index)}}
                                      value={this.state.shippingAddresses[index].street}
                                      required
                                    />
                                    <label htmlFor="street">Address Line 1</label>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <TextInput
                                    name="street2"
                                    type="text"
                                    placeholder="Street Address 2"
                                    onChange={(e) => {this.updateShippingAddress(e, index)}}
                                    value={this.state.shippingAddresses[index].street2}
                                  />
                                  <label htmlFor="street2">Address Line 2</label>
                                </div>
                                <div className="form-row">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <TextInput
                                        name="city"
                                        type="text"
                                        placeholder="City"
                                        onChange={(e) => {this.updateShippingAddress(e, index)}}
                                        value={this.state.shippingAddresses[index].city}
                                        required
                                      />
                                      <label htmlFor="city">City</label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-8">
                                    <div className={`form-group select-group ${this.state.shippingAddresses[index].state != '' ? 'has-value' : ''}`}>
                                      <SelectGroup name="state"
                                                   value={this.state.shippingAddresses[index].state}
                                                   onChange={(e) => {this.updateShippingAddress(e, index)}}
                                                   placeholder="State"
                                                   className="has-value"
                                                   required
                                      >
                                        <option value=""></option>

                                        {Utils.getStateAbbreviations().map((option) => (
                                          <option value={option}>{option}</option>
                                        ))}
                                      </SelectGroup>
                                      <label htmlFor="state">State</label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-4">
                                    <div className="form-group">
                                      <TextInput
                                        name="zip"
                                        type="text"
                                        placeholder="Zip Code"
                                        onChange={(e) => {this.updateShippingAddress(e, index)}}
                                        value={this.state.shippingAddresses[index].zip}
                                        required
                                      />
                                      <label htmlFor="zip">Zip Code</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            {index == 0 &&
                            <div ref={this.firstShippingAddressProductDetailRef}></div>
                            }

                            {this.localCache.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM)
                              && this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numLocations > 1
                              && !this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].scheduleDemo &&
                            <div>
                              <p className="mid-form">How many digital signage devices would you like shipped to this location?</p>
                              <p className="mid-form-lower">({this.getRemainingDigitalSignageDevicesDisplay()} Device{this.getRemainingDigitalSignageDevicesDisplay() != 1 ?'s' : ''} Remaining)</p>

                              <div className="form-group">
                                <TextInput
                                  name="numDigitalSignageDevices"
                                  type="number"
                                  placeholder="# of Devices"
                                  min="0"
                                  max={this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numDisplays}
                                  value={this.state.shippingAddresses[index].numDigitalSignageDevices || ''}
                                  onChange={(e) => {this.updateShippingAddress(e, index)}}
                                />
                                <label htmlFor="numDigitalSignageDevices"># of Devices</label>
                              </div>
                            </div>
                            }
                            {this.specifyLocationProducts.map((productType) => (
                            <div>
                              <Checkbox
                                key={"additionalShipping-" + productType + "-" + index}
                                name={productType}
                                label={"Include " + Constants.PRODUCT_INFO_MAP[productType].name + " at this Location?"}
                                value={this.state.shippingAddresses[index][productType] || ''}
                                onChange={(e) => {this.updateShippingAddressToggle(e, index)}}
                              />
                            </div>
                          ))}
                          </ValidationForm>
                          <hr />
                        </div>
                        ))}

                        {[].map((shippingAddress, index) => (

                        <ValidationForm
                          key={'additionalShippingAddressForm' + index}
                          onSubmit={this.handleFormSubmit}
                          onErrorSubmit={this.handleFormError}
                          ref={this.additionalShippingFormRefs[index]}
                          style={{marginBottom: "0"}}
                        >
                          <h4>Shipping Address (Location #{index+2})</h4>
                          <p>Please provide the details of the shipping address of location #{index+2}.</p>
                          <div style={{paddingTop: "20px"}}>
                            <div className="form-group">
                              <TextInput
                                name="street"
                                type="text"
                                placeholder="Street Address 1"
                                value={this.state.additionalShippingAddresses[index].street}
                                onChange={(e) => {this.updateShippingAddress(e, index)}}
                                required
                              />
                              <label htmlFor="street">Address Line 1</label>
                            </div>
                          </div>
                          <div className="form-group">
                            <TextInput
                              name="street2"
                              type="text"
                              placeholder="Street Address 2"
                              value={this.state.additionalShippingAddresses[index].street2}
                              onChange={(e) => {this.updateShippingAddress(e, index)}}
                            />
                            <label htmlFor="street2">Address Line 2</label>
                          </div>
                          <div className="form-row">
                            <div className="col-12">
                              <div className="form-group">
                                <TextInput
                                  name="city"
                                  type="text"
                                  placeholder="City"
                                  value={this.state.additionalShippingAddresses[index].city}
                                  onChange={(e) => {this.updateShippingAddress(e, index)}}
                                  required
                                />
                                <label htmlFor="city">City</label>
                              </div>
                            </div>
                            <div className="col-12 col-lg-8">
                              <div className="form-group">
                                <TextInput
                                  name="state"
                                  type="text"
                                  placeholder="State"
                                  value={this.state.additionalShippingAddresses[index].state}
                                  onChange={(e) => {this.updateShippingAddress(e, index)}}
                                  required
                                />
                                <label htmlFor="state">State</label>
                              </div>
                            </div>
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <TextInput
                                  name="zip"
                                  type="text"
                                  placeholder="Zip Code"
                                  value={this.state.additionalShippingAddresses[index].zip}
                                  onChange={(e) => {this.updateShippingAddress(e, index)}}
                                  required
                                />
                                <label htmlFor="zip">Zip Code</label>
                              </div>
                            </div>
                          </div>

                          {this.localCache.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM)
                          && !this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].scheduleDemo &&
                          <div>
                            <p className="mid-form">How many digital signage devices would you like shipped to this location?</p>
                            <p className="mid-form-lower">({this.getRemainingDigitalSignageDevicesDisplay()} Device{this.getRemainingDigitalSignageDevicesDisplay() != 1 ?'s' : ''} Remaining)</p>
                            <div className="form-group">
                              <TextInput
                                name="numDigitalSignageDevices"
                                type="number"
                                placeholder="# of Devices"
                                min={0}
                                max={this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].numDisplays}
                                value={this.state.additionalShippingAddresses[index].numDigitalSignageDevices || ''}
                                onChange={(e) => {this.updateShippingAddress(e, index)}}
                              />
                              <label htmlFor="numDigitalSignageDevices"># of Devices</label>
                            </div>
                          </div>
                          }
                          {this.specifyLocationProducts.map((productType) => (
                          <div>
                            <Checkbox
                              key={"additionalShipping-" + productType + "-" + index}
                              name={productType}
                              label={"Include " + Constants.PRODUCT_INFO_MAP[productType].name + " at this Location?"}
                              value={this.state.additionalShippingAddresses[index][productType] || ''}
                              onChange={(e) => {this.updateShippingAddressToggle(e, index)}}
                            />
                          </div>
                          ))}
                          <hr />
                        </ValidationForm>

                        ))}

                        <ValidationForm
                          onSubmit={this.handleFormSubmit}
                          onErrorSubmit={this.handleFormError}
                          ref={this.billingDetailsRef}
                          style={{marginBottom: "0"}}
                        >
                          {this.state.shippingAddresses.length > 1 &&
                          <div>
                            <h4>Billing Type</h4>
                            <p>Please select whether you prefer consolidated or separate billing.</p>
                            <Radio.RadioGroup
                              name="billingTerms|type"
                              required
                              valueSelected={this.state.billingTerms.type}
                              onChange={this.handleInputChange}
                            >

                              <Radio.RadioItem
                                label="Consolidated (Bill Primary Location Only)"
                                value="Consolidated"
                                id="billingTerms-type-Consolidated"
                                key="billingTerms-type-Consolidated"
                              />

                              <Radio.RadioItem
                                label="Individual (Bill Each Location Individually)"
                                value="Individual"
                                id="billingTerms-type-Individual"
                                key="billingTerms-type-Individual"
                              />
                            </Radio.RadioGroup>
                            <hr />
                          </div>
                          }
                          <div>
                            <h4>Contract Length</h4>
                            <p className="half-muted">Please select a starting length for your partnership with Spectrio.</p>
                            <div className="custom-radio-price-label">
                              <Radio.RadioGroup
                                name="billingTerms|contractLength"
                                required
                                valueSelected={this.state.billingTerms.contractLength || "1"}
                                onChange={this.handleInputChange}
                              >

                                <Radio.RadioItem
                                  label="1 Year"
                                  value="1"
                                  id="billingTerms-contractLength-1"
                                  key="billingTerms-contractLength-1"
                                />

                                <Radio.RadioItem
                                  label={["2 Years", <span>{this.getSavedPercentageDisplay(2)}</span>,  ""]}
                                  value="2"
                                  id="billingTerms-contractLength-2"
                                  key="billingTerms-contractLength-2"
                                />

                                <Radio.RadioItem
                                  label={["3 Years", <span>{this.getSavedPercentageDisplay(3)}</span>,  ""]}
                                  value="3"
                                  id="billingTerms-contractLength-3"
                                  key="billingTerms-contractLength-3"
                                />
                              </Radio.RadioGroup>
                            </div>
                            <hr />
                          </div>
                          <Checkbox
                            name="acceptTerms"
                            id="acceptTerms"
                            label={termsLabel}
                            errorMessage="Please check to proceed"
                            onChange={this.updateAcceptTerms}
                            value={this.state.acceptTerms}
                            required
                          />
                          <small className="fine-print">
                            You must consent to Spectrio's terms and conditions in order to proceed.
                          </small>
                        </ValidationForm>
                        </div>
                    </div>
                </div>
                <ProductPricing data={this.localCache} onItemPricingUpdated={this.itemPricingUpdated}/>
                <div className="col-12 action-row">
                  <div className="form-group mt-4 mb-0">
                    <LinkContainer to="/step-2" style={{marginRight: "5px"}}>
                      <button className="btn btn-lg">
                        Back
                      </button>
                    </LinkContainer>
                    <button
                      className="btn btn-primary btn-lg w-200"
                      data-testid="app-next-button"
                      onClick={this.submitBillingInfo}>
                      <span className={`${this.state.processingSubmit ? "d-none" : ""}`}>
                        Submit Payment
                      </span>
                      <span className={`${this.state.processingSubmit ? "" : "d-none"}`}>
                        <i className="fas fa-spinner fa-spin"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
   }
}
