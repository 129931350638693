import React, { Component } from 'react';
import MarketingHero from '../components/MarketingHero';
import { ValidationForm} from "react-bootstrap4-form-validation";
import * as Utils from "../utils";
import * as Constants from "../constants";

export default class Home extends Component {
  constructor(props, context) {
    super(props, context)

    this.localCache = Utils.getLocalCache();

    if(!this.localCache.hasOwnProperty('selectedProducts')){
      window.location.assign('/');
      return;
    }

    if(!window.location.host.startsWith('localhost'))
      Utils.clearCache();
    this.state = {
      scheduleDemoProductDisplay: this.getScheduleProductsDisplay(),
    };
  }

  getScheduleProductsDisplay(){

    let scheduleDemoProducts = [];

    if(this.localCache.selectedProducts.includes(Constants.INTERACTIVE_KIOSKS_PRODUCT_ENUM))
      scheduleDemoProducts.push('interactive kiosks');

    if(this.localCache.selectedProducts.includes(Constants.SCENT_MARKETING_PRODUCT_ENUM))
      scheduleDemoProducts.push('scent marketing');

    if(this.localCache.selectedProducts.includes(Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM) && this.localCache.productDetails[Constants.DIGITAL_SIGNAGE_PRODUCT_ENUM].scheduleDemo)
      scheduleDemoProducts.push('digital signage');

    let scheduleDemoProductDisplay = ''

    if(scheduleDemoProducts.length === 1)
      scheduleDemoProductDisplay = scheduleDemoProducts[0];
    else if(scheduleDemoProducts.length === 2)
      scheduleDemoProductDisplay = scheduleDemoProducts[0] + ' and ' + scheduleDemoProducts[1];
    else if(scheduleDemoProducts.length === 3)
      scheduleDemoProductDisplay = scheduleDemoProducts[0] + ', ' + scheduleDemoProducts[1] + ', and ' + scheduleDemoProducts[2];

    return scheduleDemoProductDisplay;
  }

  render () {
      return (
        <div className="bs-form">
          <MarketingHero />
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1">
                <ValidationForm onSubmit={(e, formData, inputs) => { e.preventDefault(); console.log(e, formData, inputs) }}>
                  <div className="card card--no-hover thanks-card">
                    <div className="mid">
                      <h2>Next Steps:</h2>
                      {/* Only show this .show-demo block the scheduling of a demo is needed. You can replace the XX below with the same {this.state.scheduleDemoProducts} that you're currently using on Step2.js */}

                      {this.state.scheduleDemoProductDisplay !== '' &&
                        <div className="show-demo">
                          <p><b className="">Schedule your demo!</b> In order to complete the registration for your <b
                            className="purple-b">{this.state.scheduleDemoProductDisplay}</b>, we'll need to do a brief call
                            with you to discuss your business needs.</p>
                          <div className="iframe mb-4">
                            <iframe
                              src={"https://app.hubspot.com/meetings/demo-booking?embed=true&amp;parentHubspotUtk=9bc33f7260c164e4ff2239a9b650c40d&amp;parentPageUrl=https://www.spectrio.com/thank-you/" +
                              "&firstName=" + this.localCache.userDetails.firstName +
                              "&lastName=" + this.localCache.userDetails.lastName +
                              "&email=" + this.localCache.userDetails.email +
                              "&company=" + this.localCache.userDetails.organization
                              }
                              title="Schedule a Demo"
                              width="100%"
                              style={{minWidth: "312px", minHeight: "516px", height: "695px", border: "none"}}
                            ></iframe>
                          </div>
                        </div>
                      }
                      {/* Only show this .show-reg if they submitted a credit card. */}
                      {this.localCache.orderSubmitted &&
                        <div className="show-reg">
                          <p className="no-pad">
                            A Spectrio representative will reach out to you as soon as possible to discuss installation
                            and content creation.
                          </p>
                          <br/>
                          <b>Your card will not be billed until your hardware ships.</b>
                        </div>
                      }
                    </div>
                  </div>
                </ValidationForm>
              </div>
            </div>
          </div>
        </div>
      )
   }
}
